/* eslint-disable react/prop-types */
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./docs.scss"

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      tableOfContents(pathToSlugField: "fields.slug")
      frontmatter {
        title
      }
    }
  }
`
const Menu = ({ name, items }) => (
  <section className={`doc-index__${name}`}>
    <h6 className="title">{name}</h6>
    <ul>
      {items.map(item => (
        <li key={item.id}>
          <Link to={item.url} activeClassName="active">
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  </section>
)

const Template = ({
  data: {
    markdownRemark: {
      frontmatter: { title },
      html,
      tableOfContents,
    },
  },
  pageContext: { menus = {} },
}) => {
  return (
    <Layout className="docs">
      <SEO title={title} />
      <Container className="max-width">
        <Row>
          <Col
            as="aside"
            className="table-of-contents"
            lg={{ span: 2, order: 2 }}
          >
            <div className="sticky">
              <h6 className="title">Table of Contents</h6>
              <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
            </div>
          </Col>
          <Col
            as="article"
            lg={{ span: 8, order: 1 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Col as="aside" className="doc-index" lg={{ span: 2, order: 0 }}>
            {menus["helix"] && <Menu name="Helix" items={menus["helix"]} />}
            {menus["helix-devtools"] && (
              <Menu name="Developer Tools" items={menus["helix-devtools"]} />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Template
